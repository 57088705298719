var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('header', {
    attrs: {
      "id": "topnav-login"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "border-bottom-nav",
    attrs: {
      "id": "navigation"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-6"
  }, [_c('ul', {
    directives: [{
      name: "scroll-spy-active",
      rawName: "v-scroll-spy-active",
      value: {
        selector: 'li.has-submenu'
      },
      expression: "{ selector: 'li.has-submenu' }"
    }],
    staticClass: "navigation-menu mb-0"
  }, [_c('li', {
    staticClass: "has-submenu"
  }, [_c('a', {
    attrs: {
      "href": "https://awarisgroup.com/"
    }
  }, [_c('i', {
    staticClass: "mdi mdi-chevron-left mr-1"
  }), _vm._v(_vm._s(_vm.$t("navbar.homepage")))])])])]), _c('div', {
    staticClass: "col-lg-6"
  }, [_c('div', {
    staticClass: "text-white text-right pr-4 btn-hai"
  }, [_c('p', {
    staticClass: "p-lang"
  }, [_vm._v(" " + _vm._s(_vm.$t("lang")) + " "), _c('span', {
    staticClass: "lang-bg ml-2"
  }, [_c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.changeLocale('ms');
      }
    }
  }, [_vm._v(" BM ")]), _vm._v(" | "), _c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.changeLocale('en');
      }
    }
  }, [_vm._v(" ENG ")])])])])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container",
    staticStyle: {
      "margin-top": "100px"
    }
  }, [_c('div', {
    staticClass: "bg-overlay bg-overlay-white bg-overlay-img"
  }), _c('div', {
    staticClass: "row justify-content-center",
    staticStyle: {
      "margin-bottom": "50px"
    }
  }, [_c('div', {
    staticClass: "card verification-page bg-white shadow rounded-login border-0"
  }, [_c('div', {
    staticClass: "card-body-login"
  }, [_vm._v(" " + _vm._s(_vm.face_verification_count) + " "), _vm.wasiat ? _c('div', {
    staticClass: "row"
  }, [!_vm.ekyc_verified_at && _vm.ekyc_fail <= 0 ? _c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('ekyc', {
    attrs: {
      "wasiat": _vm.wasiat
    },
    on: {
      "failcount": _vm.eventHandler
    }
  })], 1) : _vm._e(), !_vm.ekyc_verified_at && _vm.ekyc_fail > 0 ? _c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('manual-ekyc', {
    attrs: {
      "wasiat": _vm.wasiat
    }
  })], 1) : _vm._e(), _vm.ekyc_verified_at ? _c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('ekyc-complete', {
    attrs: {
      "wasiat": _vm.wasiat
    }
  })], 1) : _vm._e()]) : _vm._e()])])])])]), _c('footer', {
    staticClass: "footer-login footer-bar"
  }, [_c('div', {
    staticClass: "container text-center"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12 mb-3"
  }, [_c('div', {
    staticClass: "text-sm-center"
  }, [_c('p', {
    staticClass: "mb-3"
  }, [_c('strong', [_vm._v("©2021 AWARIS. " + _vm._s(_vm.$t("copyright")) + ".")]), _c('br'), _vm._v(" " + _vm._s(_vm.$t("poweredby")) + " "), _c('strong', [_vm._v("PROOFFICE")])])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }